/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * Login
 *
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { useLoginSlice } from './slice';
import { selectLogin } from './slice/selectors';
import './styles.scss';
import { useQuery, validateEmail } from 'utils/commons';
import { toast } from 'react-toast';

interface Props {}

export function Login(props: Props) {
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState('');
  const [password, setPassword] = useState('');
  const [securityLoginNeeded, setSecurityLoginNeeded] = useState(false);
  const [isResendVerifyVisible, setIsResendVerifyVisible] = useState(false);

  const [
    isForgotPasswordFormVisible,
    setIsForgotPasswordFormVisible,
  ] = useState(false);

  const [verifiedUser, setVerifiedUser] = useState(false);
  const [isAppsumoEmail, setIsAppsumoEmail] = useState(false);
  const [isAppsumoPurchase, setIsAppsumoPurchase] = useState(false);




  const { actions } = useLoginSlice();
  const loginState = useSelector(selectLogin);
  const dispatch = useDispatch();

  const params = useQuery();
  const history = useHistory();

  useEffect(() => {
    return () => {
      dispatch(actions.resetLoginError());
    };
  }, []);

  useEffect(() => {
    
    if (params.get('errorText')) {
      dispatch(actions.loginError({ message: params.get('errorText') }));
      params.delete('errorText');
    }
    if (window.location.href.includes('guest') && params.get('return_to') !== null) {
      history.push(decodeURIComponent(params.get('return_to') as string));
    } else if (params.get('return_to') ) { 
      setSecurityLoginNeeded(true);
    }

    if (params.get('verified')) {
      setVerifiedUser(true);
    }

    const isAppSumo = params.get('appSumo');
    const queryEmail = params.get('email');

    if (isAppSumo) {
      if (window.location.href.indexOf('login?email=')) {
        setIsAppsumoPurchase(true);
      }
      setIsAppsumoEmail(true);
    } else {
      setIsAppsumoEmail(false);
    }

    if (queryEmail) {
      updateEmail(queryEmail);
      setEmail(queryEmail || '');
    }
  }, [params]);

  const login = e => {
    e.preventDefault();

    dispatch(
      actions.login({
        request: {
          email,
          password,
        },
        verifyCb: () => {
          setIsResendVerifyVisible(true);
        },
        setPasswordCb: () => {
          history.push(routes.newAccount + '?email=' + email);
        },
        successCb: user => {
          if (isAppsumoEmail) {
            toast.success(
              'Congratulation, your account has been upgraded successfully.',
            );
          }
        },
      }),
    );
  };

  const requestPasswordReset = e => {
    e.preventDefault();
    dispatch(
      actions.requestPasswordReset({
        request: {
          email,
        },
        successCb: () => {
          setIsForgotPasswordFormVisible(false);
          
        },
      }),
    );
  };

  const updateEmail = email => {
    const emailStateIs = validateEmail(email);
    if (emailStateIs) {
      setEmail(email);
      setValidEmail('true');
    } else {
      setEmail(email);
    }
  };

  useEffect(() => {
    if (loginState.isLoggedIn) {
      // console.log(loginState.user);
      if (loginState.user?.email?.includes('sandbox')) {
        localStorage.setItem('sandBox', 'true');
      } else {
        localStorage.removeItem('sandBox');
      }

      if (loginState.user.profileCompletion) {
        const returnTo = params.get('return_to');
        if (returnTo) {
          history.push(returnTo);
        } else if (loginState.user.primaryCompany)
          history.push(routes.companyDashboard(loginState.user.primaryCompany));
        else history.push(routes.dashboard);
      } else {
        history.push(routes.completeAccount(loginState.user.id));
      }
    }
  }, [loginState.isLoggedIn]);

  const onResendVerify = () => {
    dispatch(
      actions.doResendVerifyLink({
        email,
      }),
    );
  };

  return (
    <div className="login-wrapper d-flex flex-column align-items-center justify-content-center bg-primary">
      <div className="formHolder">
        <div className="loginBox p-lg">
          {loginState.resendVerifyLinkSuccess ? (
            <div className="align-items-align-center">
              <img
                width={100}
                src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/animated-icon-email-success.gif"
                className="successIcon"
                alt=""
              />

              <h2 className="successMessage">Success</h2>
              <p>A verification code has been emailed to</p>
              <code>{email}</code>
              <div className="transitionedInputWrapper mb-17">
                <div className="text-info mt-20">
                Please open the email you have received and follow the instructions provided to complete the setup process for your account.
                </div>
              </div>
            </div>
          ) : (
            <>
              {securityLoginNeeded ? (
                <img
                  width={90}
                  className="lockIcon"
                  src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/lock.png"
                  alt=""
                />
              ) : verifiedUser ? (
                <div className="verifiedIcon">
                  <img
                    width={108}
                    src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/verified.png"
                    alt=""
                  />
                </div>
              ) : (
                <div className="logoIMG">
                  <img
                    width={90}
                    src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/animated-logo-huge.gif"
                    alt=""
                  />
                </div>
              )}

              {isResendVerifyVisible ? (
                <div className="title">Ooops</div>
              ) : !isResendVerifyVisible && securityLoginNeeded ? (
                <>
                  <div className="title">Security Check!!!</div>
                </>
              ) : verifiedUser ? (
              <>  <div className="title">Thank You!</div>
                
                </>
              ) : isAppsumoPurchase ? (
                <>
                  <div className="title">👋 Welcome Sumo-ling!</div>
                </>
              ) : isForgotPasswordFormVisible ? (<div className="title">Reset Password</div>) : (
                <div className="title">👋 Welcome Back</div>
              )}

              {!isResendVerifyVisible &&
                !securityLoginNeeded &&
                !isAppsumoPurchase &&
                !verifiedUser &&
                !isForgotPasswordFormVisible && (
                  <>
                    <div className="subTitle">Please login below</div>
                  </>
                )}
                {verifiedUser && (
                  <>
                  <div className="subTitle">Your email has been verified. Please log in to continue setting up your account.</div>
                  </>
                )}
              {isAppsumoPurchase && (
                <>
                  <div className="subTitle">
                    Please login below to complete
                    <br />
                    your AppSumo purchase.
                  </div>
                </>
              )}
              {!isResendVerifyVisible &&
                securityLoginNeeded &&
                !isForgotPasswordFormVisible && (
                  <>
                    <div className="subTitle">
                      To view this document please login.
                    </div>
                  </>
                )}
              <form className="d-flex flex-column login-form" onSubmit={login}>
                {!isResendVerifyVisible && !isForgotPasswordFormVisible && (
                  <>
                    <div className="transitionedInputWrapper">
                      <label className="inp">
                        <input
                          required
                          value={email}
                          type="email"
                          onChange={e => updateEmail(e.target.value)}
                          placeholder="&nbsp;"
                          disabled={isAppsumoEmail}
                        />
                        <span className="label">Email</span>
                        <span className="focus-bg"></span>
                      </label>
                    </div>

                    <div className="transitionedInputWrapper">
                      <label className="inp">
                        <input
                          required
                          value={password}
                          type="password"
                          autoComplete="password"
                          onChange={e => setPassword(e.target.value)}
                          placeholder="&nbsp;"
                        />
                        <span className="label">Password</span>
                        <span className="focus-bg"></span>
                      </label>
                    </div>

                    <div className="transitionedInputWrapper d-flex justify-content-center align-items-center">
                      <button
                        className={`full-width mb-17 mt-20 primaryButton large-btn bold loginButton ' ${
                          loginState.isLoggingIn ? 'roundSpinnerButton' : ''
                        }`}
                        // style={{display: `${loginState.isLoggingIn ||
                        //   email === '' ||
                        //   password === '' ||
                        //   !validEmail ? 'none': 'flex' }`}}
                        onClick={() => {
                          setIsResendVerifyVisible(false);
                          actions.resetLoginError();
                        }}
                        disabled={
                          loginState.isLoggingIn ||
                          email === '' ||
                          password === '' ||
                          !validEmail
                        }
                      >
                        {!loginState.isLoggingIn ? (
                          'Sign in'
                        ) : (
                          <i className="gg-spinner-two"></i>
                        )}
                      </button>
                    </div>
                  </>
                )}
                {/* login error */}
                {loginState.loginError && (
                  <div className="transitionedInputWrapper">
                    <div
                      className={` 
                     ${
                       isResendVerifyVisible ? 'staticToast' : 'staticToast'
                     }  `}
                    >
                      <img
                        width="50"
                        src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/aniamted-error-icon-light.gif"
                        alt=""
                      />
                      {isResendVerifyVisible ? (
                        <p>Your email is pending verification.</p>
                      ) : (
                        <p>
                          {loginState.loginError}
                          {params.get('errorRedirectUrl') ? (
                            <span>
                              &nbsp; please click here to return to{'  '}&nbsp;
                              <Link to={params.get('errorRedirectUrl') || ''}>
                              Workspace
                              </Link>
                            </span>
                          ) : null}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </form>

              {isResendVerifyVisible && (
                <div className="transitionedInputWrapper mb-17">
                  <p className="mb-10">
                    Please click the verification link we sent to your email.
                  </p>
                  <button

                    onClick={() => {
                      setIsResendVerifyVisible(false);
                      actions.resetLoginError();
                      onResendVerify();
                    }}
                    className={`full-width mb-17  large-btn bold' 
                                 ${
                                   loginState.isResendingVerifyLink
                                     ? 'roundSpinnerButton'
                                     : ''
                                 }
                                      ${
                                        loginState.resendVerifyLinkSuccess
                                          ? 'successImage'
                                          : 'blueButton'
                                      }
                                  `}
                    disabled={loginState.isResendingVerifyLink}
                  >
                    {loginState.isResendingVerifyLink ? (
                      <i className="gg-spinner-two"></i>
                    ) : (
                      <>Resend verification email</>
                    )}
                  </button>
                </div>
              )}
              {isForgotPasswordFormVisible && (
                <div className="transitionedInputWrapper mb-17">
                  <div className="subTitle">
                    Please enter your email address below. If your email is associated with an account and has been activated, you will receive a Reset Password link.
                  </div>
                  <form
                    className="d-flex flex-column login-form"
                    onSubmit={requestPasswordReset}
                  >
                    <div className="transitionedInputWrapper">
                      <label className="inp">
                        <input
                          required
                          value={email}
                          type="email"
                          onChange={e => updateEmail(e.target.value)}
                          placeholder="&nbsp;"
                          disabled={isAppsumoEmail}
                        />
                        <span className="label">Email</span>
                        <span className="focus-bg"></span>
                      </label>
                    </div>

                    <div className="transitionedInputWrapper d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        className={`full-width mb-17 mt-10 primaryButton large-btn bold' ${
                          loginState.isRequestingPasswordReset
                            ? 'roundSpinnerButton'
                            : ''
                        }`}
                        onClick={() => {
                          setIsResendVerifyVisible(false);
                          actions.resetLoginError();
                        }}
                        disabled={
                          loginState.isRequestingPasswordReset ||
                          email === '' ||
                          !validEmail
                        }
                      >
                        {!loginState.isRequestingPasswordReset ? (
                          'Submit'
                        ) : (
                          <i className="gg-spinner-two"></i>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {loginState.resendVerifyLinkError && (
                <div className="transitionedInputWrapper mb-17">
                  <div className="staticToast">
                    <img
                      width="50"
                      src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/aniamted-error-icon-light.gif"
                      alt=""
                    />
                    {loginState.resendVerifyLinkError}
                  </div>
                </div>
              )}

              {/* need an account  */}
              {!verifiedUser && !isResendVerifyVisible && !securityLoginNeeded && (
                <div className="transitionedInputWrapper mb-0">
                  <div className="text-info mt-20">
                    {!isResendVerifyVisible ? (
                      <>
                        Don't have an account?{' '}
                        <Link
                          to={routes.newAccount}
                          className="link-text underline-type-1"
                        >
                          Sign up for free
                        </Link>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            setIsResendVerifyVisible(false);
                            actions.resetLoginError()
                          }}
                          className="link-text "
                        >
                          Finish
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}

              {!isForgotPasswordFormVisible && !isResendVerifyVisible && (
                <div className="transitionedInputWrapper mb-0">
                  <div className="text-info mt-10">
                    Trouble logging in?{' '}
                    <button
                      onClick={() => {
                        setIsForgotPasswordFormVisible(true);
                        setIsResendVerifyVisible(false);
                        actions.resetLoginError();
                      }}
                      className="link-text "
                    >
                      Request new password
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
  <hr className='mt-20' />
<div className='mt-20'>©2024 <a href="https://www.contracthub.com" target='_blank'>ContractHub, Inc.</a> </div> All rights reserved

        </div>


        {/* <Link to={routes.forgotPassword} className="link-text">
        Forgot password
      </Link> */}
      </div>
    </div>
  );
}
