/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * IndividualDashboard
 *
 */
import { LayoutWrapper } from 'app/components/LayoutWrapper/Loadable';
import { useLayoutSlice } from 'app/components/LayoutWrapper/slice';
import { selectLayout } from 'app/components/LayoutWrapper/slice/selectors';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Moment from 'react-moment';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSObject } from 'styled-components';
import { routes } from 'utils/routes';
import { useIndividualDashboardSlice } from './slice';
import { selectIndividualDashboard } from './slice/selectors';
import './styles.scss';
import { useQuery } from 'utils/commons';
// import Dropdown from 'react-dropdown';
import { toast } from 'react-toast';
import { DeleteAgreementConfirmModal } from 'app/components/DeleteAgreementConfirmModal';
import { useViewDocumentSlice } from '../ViewDocument/slice';
import { selectViewDocument } from '../ViewDocument/slice/selectors';

interface Props {}

const individualFoldersList = [
  {
    id: 0,
    name: 'My To Do',
    folder: 'myTodo',
    icon: 'folder',
  },
  {
    id: 1,
    name: 'In-Progress',
    folder: 'sent',
    icon: 'folder',
  },
  {
    id: 2,
    name: 'Completed',
    folder: 'completed',
    icon: 'folder',
  },
  {
    id: 3,
    name: 'All',
    folder: 'all',
    icon: 'folder',
  },
  // {
  //   id: 4,
  //   name: 'Bin',
  //   folder: 'bin',
  //   icon: 'trash',
  // },
];
let startTour = id => {
  window.USETIFUL?.tour?.start(id);
};

const templateMenu = [
  { value: 'download', label: 'Download' },
  { value: 'delete', label: 'Delete' },
];

const tableStyle: {
  ['rows']: {
    style: CSSObject & any;
  };
  ['headRow']: {
    style: CSSObject & any;
  };
  ['headCells']: {
    style: CSSObject & any;
  };
  ['cells']: {
    style: CSSObject;
  };
} = {
  rows: {
    style: {
      minHeight: '99px',
      border: '1px solid var(--sidebar-border) !important',
      marginTop: '-1px',
      borderLeft: 'none !important',
      ':hover': {
        // transform: 'scale(1.002)',
      },
    },
  },
  headRow: {
    style: {
      borderBottomSize: '1px',
      borderColor: '#E8EEF7',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      textTransform: 'capitalize',
    },
  },
};
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
const folderIcon = 'fi fi-rr-folder s15pt';

export function IndividualDashboard(props: Props) {
  const [filterText, setFilterText] = useState('');
  const [sortBy, setSortBy] = useState('newest');

  const [
    isDeleteConfirmModalVisible,
    setIsDeleteConfirmModalVisible,
  ] = useState(false);
  const [deleteSelectedDoc, setDeleteSelectedDoc] = useState<any>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterOptions, setFilterOptions] = useState<any>({
    drafter: '',
    status: [],
  });
  const history = useHistory();
  const doClickRow = (row, event) => {
    row.isIndividualDocument
      ? history.push(routes.viewSelfDocument(row.agrUID))
      : history.push(
          routes.viewIndividualDocument(row?.company?.companyId, row.agrUID),
        );
  };
  const { actions: layoutActions } = useLayoutSlice();
  const { actions } = useIndividualDashboardSlice();
  const dashboardState = useSelector(selectIndividualDashboard);
  const layoutState = useSelector(selectLayout);

  const { actions: viewDocActions } = useViewDocumentSlice();
  const viewDocumentState = useSelector(selectViewDocument);

  const dispatch = useDispatch();

  const params = useQuery();

  useEffect(() => {
    dispatch(layoutActions.setSelectedCompany({ company: null }));

    const isSandboxLink = params.get('sandboxLink');

    if (isSandboxLink) {
      window.USETIFUL?.tour?.start(32544);
      // console.log('You are in sandbox mode');
    }

    return () => {
      dispatch(layoutActions.resetSelectedFolder());
    };
  }, []);

  useEffect(() => {
    if (layoutState.user) fetchIndAgreements();
    // else {
    //   history.push(routes.login);
    // }
  }, [layoutState.user]);

  useEffect(() => {
    dispatch(
      actions.changeSelectedFolderDocuments(layoutState.selectedFolder.folder),
    );
  }, [layoutState.selectedFolder]);

  const fetchIndAgreements = () => {
    dispatch(
      actions.getDocuments({
        userId: layoutState.user._id,
        selectedFolder: layoutState.selectedFolder.folder,
      }),
    );
  };

  const onSelectFolder = folder => {
    // console.log(folder);
    dispatch(layoutActions.setSelectedFolder(folder));
    // console.log(layoutState.selectedFolder);
  };

  const onDeleteClick = row => {
    if (row?.agreement?.agreementStatus !== 'deleted') {
      setDeleteSelectedDoc(row);
      setIsDeleteConfirmModalVisible(true);
    } else if (
      row?.agreement?.agreementStatus === 'deleted' &&
      layoutState.userRoles?.includes('Admin')
    ) {
      setDeleteSelectedDoc(row);
      setIsDeleteConfirmModalVisible(true);
    } else {
      toast.warn('This agreement cannot be deleted');
    }
  };

  const onDeleteDocumentConfirm = () => {
    const request = {
      ...deleteSelectedDoc,
      agreement: {
        ...deleteSelectedDoc.agreement,
        agreementStatus: 'deleted',
      },
      userEmail: layoutState.user.email,
    };

    dispatch(
      viewDocActions.updateDocument({
        request,
        type: 'delete',
        successCb: () => {
          setIsDeleteConfirmModalVisible(false);

          setDeleteSelectedDoc(null);
          fetchIndAgreements();
        },
      }),
    );
  };

  const filteredAgreements = dashboardState.documents
    ? dashboardState.documents
        ?.slice()
        .sort((agr1, agr2) => {
          const firstDate = new Date(agr1.dates.draftDate).getTime();
          const secondDate = new Date(agr2.dates.draftDate).getTime();

          return sortBy === 'oldest'
            ? firstDate - secondDate
            : secondDate - firstDate;
        })
        .filter(
          agreement =>
            agreement &&
            (agreement.agreement.agreementTitle
              .split(' ')
              .map(name => name.toLowerCase())
              .findIndex(name =>
                name.toLowerCase().startsWith(filterText.toLowerCase()),
              ) > -1 ||
              agreement.addedParties
                .reduce(
                  (parties, party) => [...parties, party.name.toLowerCase()],
                  [],
                )
                .findIndex(name => name.startsWith(filterText.toLowerCase())) >
                -1) &&
            (!filterOptions.drafter ||
              agreement.drafter.userName
                .toLowerCase()
                .startsWith(filterOptions.drafter)) &&
            (filterOptions.status?.length === 0 ||
              filterOptions.status.includes(agreement.userStatus)),
        )
    : [];
  // console.log(filteredAgreements);

  return (
    <>
      <LayoutWrapper title="Library">
        <div
          className="whitePanel pt-10 pb-10 text-center dashboardBanner"
          style={{ backgroundColor: '#87BEFF' }}
        >
          Welcome, To your Personal workspace. Create a Company workspace to unlock powerful features like sending documents for signature, uploading templates, and inviting your team to collaborate.
          {' '}<a
            href=""
            className="color-primary ml-10"
            onClick={e => {
              history.push(routes.createCompany);
              return false;
            }}
          >
            Create a Company Workspace
          </a>
          {/* <button
                      className="blueButton"
                      onClick={e => {
                        history.push(routes.createCompany);
                        return false;
                      }}
                    >
                      Create Company
                    </button> */}
        </div>

        <div className="viewSearchBar_wrapper">
          <div className="sortBy min-w-">
            {/* 0 2px 6px 0 rgb(136 148 171 / 20%), 0 24px 20px -24px rgb(71 82 107 / 10%) */}
            <div className="greyButton d-inline-flex align-items-center dropdownCustom">
              {/* <Icon.Filter size="20" className='mr-10' /> */}
              <i className="gg-sort-az"></i>
              <select
                title="Sort Documents"
                onChange={e => setSortBy(e.target.value)}
                value={sortBy || undefined}
              >
                <option value="newest">Newest to oldest</option>
                <option value="oldest">Oldest to newest</option>
              </select>
            </div>
          </div>
          {/* <div className="sortBy">
            <button className="greyButton">Sort</button>

            <select
              onChange={e => setSortBy(e.target.value)}
              value={sortBy || undefined}
            >
              <option value="newest">Newest to oldest</option>
              <option value="oldest">Oldest to newest</option>
            </select>
          </div> */}

          <div className="viewSearchBar_wrapper-searchInput">
            <div className="search-wrapper">
              <input
                className="search-input"
                type="text"
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
                placeholder="Search documents by name, party, or email"
              ></input>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="feather feather-search"
                viewBox="0 0 24 24"
              >
                <defs></defs>
                <circle cx="11" cy="11" r="8"></circle>
                <path d="M21 21l-4.35-4.35"></path>
              </svg>
            </div>
          </div>
        </div>

        <div className="library-wrapper">
          <div className="body-extras">
            <div className="extras-header">
              <div className="title">Folders</div>
            </div>
            <div className="folders-list">
              {individualFoldersList.map((folder, index) => (
                <div
                  className={`folder-item ${
                    layoutState.selectedFolder.name === folder.name
                      ? 'active'
                      : ''
                  }`}
                  key={index}
                  onClick={() => onSelectFolder(folder)}
                >
                  <i className={folderIcon}></i>
                  <div className="folder-name">{folder.name}</div>
                </div>
              ))}
            </div>

            {/* <UploadArea /> */}
          </div>

          <div className="pagePadder overflow-y">
            {filteredAgreements.length > 0 && !filterText ? (
              <DataTable
                pagination
                selectableRows
                progressPending={dashboardState.isGettingUserDocuments}
                onRowClicked={doClickRow}
                columns={[
                  {
                    name: 'Title',
                    sortable: true,
                    grow: 2,
                    cell: row => (
                      <Link
                        to={
                          row.isIndividualDocument
                            ? routes.viewSelfDocument(row.agrUID)
                            : routes.viewIndividualDocument(
                                row?.company?.companyId,
                                row.agrUID,
                              )
                        }
                      >
                        {row.agreement.agreementTitle}
                      </Link>
                    ),
                  },
                  // status
                  {
                    name: 'Status',
                    sortable: true,
                    right: true,
                    grow: 1,
                    cell: row =>
                      row.userStatus && (
                        <span
                          data-tag="allowRowEvents"
                          className={`tableBadge w-100 centerText strong ${row.userStatus}`}
                        >
                          {row.userStatus.replace('_', ' ')}
                        </span>
                      ),
                  },
                  // with
                  {
                    name: 'With',
                    sortable: true,
                    grow: 1,
                    cell: row =>
                      row.drafter &&
                      (row.isIndividualDocument ? (
                        <span
                          data-tag="allowRowEvents"
                          className="tableBadge whiteBadge"
                        >
                          {row.drafter.userName}
                        </span>
                      ) : (
                        <span
                          data-tag="allowRowEvents"
                          className="tableBadge whiteBadge"
                        >
                          {row.company?.companyName}
                        </span>
                      )),
                  },
                  // effective date
                  {
                    name: 'Effective Date',
                    sortable: true,
                    grow: 1,
                    cell: row => (
                      <>
                        {row.dates?.effectiveDate && (
                          <span className="tableBadge whiteBadge">
                            <Moment local format="MMMM DD, YYYY">
                              {row.dates?.effectiveDate}
                            </Moment>
                          </span>
                        )}
                      </>
                    ),
                  },
                                    // effective date
                                    {
                                      name: '',
                                      sortable: false,
                                      grow: 0.5,
                                      cell: row => (
                                        <>
                                          <div className='tableActionButtonGroup'>   
                                          {row?.agreement?.agreementStatus !== 'deleted' && (
                                            <a
                                              data-tip="Delete document"
                                              data-for="message"
                                              data-type="info"
                                              className="tableActionButton tableActionButton-danger pointer"
                                              onClick={() => onDeleteClick(row)}
                                            >
                                              <i className="gg-trash-empty"></i>
                                            </a>
                                          )}
                                          </div>
                                        </>
                                      ),
                                    },
                ]}
                data={filteredAgreements}
                fixedHeader
                customStyles={tableStyle}
                selectableRowsComponentProps={selectProps}
              />
            ) : (
              <div className="EmptyTableMessageContainer">
                {/* {console.log(layoutState.selectedFolder.folder)} */}
                {layoutState.selectedFolder.folder === 'myTodo' ? (
                  <div className="flex-align-start flex-row text-align-left w-80">
                    <img
                      width={90}
                      src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/myTasks.png"
                      alt=""
                    />
                    <div>
                      <h2>Hi {layoutState.user?.firstName || ''},</h2>
                      Nothing needs your attention
                    </div>
                  </div>
                ) : layoutState.selectedFolder.folder === 'bin' ? (
                  <img
                    width={90}
                    src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/trash.png"
                    alt=""
                  />
                ) : layoutState.selectedFolder.folder === 'all' ? (
                  <img
                    width={90}
                    src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/AllDocs.png"
                    alt=""
                  />
                ) : layoutState.selectedFolder.folder === 'completed' ? (
                  <img
                    width={90}
                    src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/completed.png"
                    alt=""
                  />
                ) : layoutState.selectedFolder.folder === 'sent' ? (
                  <img
                    width={90}
                    src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/inProgress.png"
                    alt=""
                  />
                ) : (
                  <img
                    width={90}
                    src="https://contracthub-static-assets.s3.ap-south-1.amazonaws.com/empty.png"
                    alt=""
                  />
                )}

                <div
                  style={{
                    flexDirection: 'column',
                    width: '80%',
                    textAlign: 'center',
                  }}
                >
                  {layoutState.selectedFolder.folder === 'sent' ? (
                    <>
                      <h2>No Documents found.</h2>
                      <span>
                        🧠 All <strong>"Pending"</strong> documents will be
                        placed here.
                      </span>
                    </>
                  ) : layoutState.selectedFolder.folder === 'completed' ? (
                    <>
                      <h2>No Documents found.</h2>
                      <span>
                        🧠 All <strong>"Completed"</strong> documents will
                        appear here.
                      </span>
                    </>
                  ) : layoutState.selectedFolder.folder === 'all' ? (
                    <>
                      <h2>No Documents found.</h2>
                      <span>
                        🧠 This is where you can view all your documents without
                        a filter.
                      </span>
                    </>
                  ) : layoutState.selectedFolder.folder === 'bin' ? (
                    <>
                      <h2>No Documents found.</h2>
                      <span>
                        🧠 When you delete a document it will appear here.
                      </span>
                    </>
                  ) : layoutState.selectedFolder.folder === 'myTodo' ? (
                    <>
                      <div className="infoContainer-body-content">
                        <hr />
                        <h2 className="text-align-left">Learn</h2>
                        <ul>
                          <li>
                            <h2>📃 Sign a document</h2>
                            Upload a document for signature <br />
                            <button
                              onClick={() => {
                                startTour(32544);
                              }}
                              className="small-white-button mt-10"
                            >
                              Learn how
                            </button>
                          </li>
                          <li>
                            <h2>Company Dashboards</h2>
                            ✅ Create approval flows,
                            <br />✅ Save time with templates,
                            <br />✅ send documents for signature <br /> ✅Plus
                            more
                            <br />
                            <button
                              onClick={() => {
                                history.push(routes.createCompany);
                              }}
                              className="small-white-button mt-10"
                            >
                              Get Started
                            </button>
                          </li>
                        </ul>
                      </div>
                      {/* <div><span style={{fontSize: '25px', position: 'relative', top: '2px'}}>📑</span> When a document requires your attention it will
                        appear here.</div>
                      <div><span style={{fontSize: '25px', position: 'relative', top: '2px'}}>✍️</span> Upload a document to sign</div> */}
                    </>
                  ) : (
                    <>
                      This folder is <span>empty.</span>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {isDeleteConfirmModalVisible && (
          <DeleteAgreementConfirmModal
            isOpen={isDeleteConfirmModalVisible}
            closeModal={() => setIsDeleteConfirmModalVisible(false)}
            onDeleteAgreementConfirm={onDeleteDocumentConfirm}
            isDeletingDoc={viewDocumentState.isDeletingDoc}
          />
        )}
      </LayoutWrapper>
    </>
  );
}
